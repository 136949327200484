<template>
    <v-container>
        <v-row justify="center" class="pt-5">
            <h1 v-show="loginUsernameInput" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithIframe && !isActivatingLoginShield" class="text-h4 font-weight-light">Login</h1>
            <h1 v-show="loginWithIframe && isActivatingLoginShield" class="text-h4 font-weight-light">Create new account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="6" class="px-10 py-5 pt-10 mt-5" v-show="loginUsernameInput">
                    <v-form v-model="loginUsernameForm" ref="loginUsernameFormRef" @submit="validateUsername" onSubmit="return false;">
                        <v-text-field
                            v-model=username
                            label="Email Address"
                            :rules="usernameRules"
                            color="purple"
                            autofocus
                            outlined
                            validate-on-blur
                            ref="usernameField"
                        ></v-text-field>
                        <v-row justify="center" v-if="loginshieldStartError">
                            <p class="text-body-1 font-weight-light red--text">Login failed</p>
                        </v-row>
                        <v-card-actions>
                            <v-row justify="center">
                                <v-btn elevation="6" class="purple white--text" @click="validateUsername">
                                    <font-awesome-icon icon="check" fixed-width/>
                                    <span class="ml-2">Next</span>
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-form>
                </v-card>
                <v-row justify="center" v-show="loginWithIframe" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { loginshieldInit } from '@loginshield/realm-client-browser';
import { isValidEmail } from '@/sdk/input';

export default {
    data() {
        return {
            loginUsernameInput: true,
            loginWithIframe: false,
            loginUsernameForm: null,
            isActivatingLoginShield: false,
            username: null,
            usernameRules: [
                (v) => isValidEmail(v) || 'Email address is required',
            ],
            loginshieldStartError: false,
        };
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            account: (state) => state.account,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },

    methods: {
        init() {
            // temporary cryptium id until we fix loginshield integration
            if (this.$route.query.cryptium_id_token) {
                this.verifyCryptiumIdToken();
                return;
            }

            if (this.$route.query.mode === 'resume-loginshield' && this.$route.query.loginshield) {
                // if the client arrives with this query parameter it
                // indicates a loginshield authentication in progress,
                // and the user probably arrived via a safety redirect
                console.log('login: resume loginshield authentication mode');
                this.loginUsernameInput = false;
                this.loginWithIframe = true;
                this.resumeLoginShield({ forward: this.$route.query.loginshield, rememberMe: true });
                return;
            }
            if (this.$route.query.mode === 'activate-loginshield') {
                // new user registration or reset
                console.log('login: activate loginshield mode');
                this.loginUsernameInput = false;
                this.loginWithIframe = true;
                this.isActivatingLoginShield = true;
                this.startLoginShield({
                    mode: 'activate-loginshield', interactionId: this.interactionId, username: this.username, rememberMe: true,
                });
                return;
            }
            if (this.isAuthenticated) {
                this.$router.push('/dashboard');
            }
        },
        async startCryptiumId({ email }) {
            try {
                const response = await this.$store.dispatch('startCryptiumId', { email });
                console.log(`startCryptiumId response: ${JSON.stringify(response)}`);
                if (response?.intent === 'redirect' && typeof response.redirect === 'string') {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('failed to activate Cryptium ID token', err);
            }
        },
        async verifyCryptiumIdToken() {
            try {
                const response = await this.$store.dispatch('verifyCryptiumId', { cryptium_id_token: this.$route.query.cryptium_id_token });
                console.log(`verifyCryptiumIdToken response: ${JSON.stringify(response)}`);
                if (response?.isAuthenticated) {
                    this.$router.push('/dashboard');
                }
            } catch (err) {
                console.error('failed to verify Cryptium ID token', err);
            }
        },
        validateUsername() {
            if (this.$refs.loginUsernameFormRef.validate()) {
                this.loginUsername();
            }
        },
        resetErrors() {
            this.loginshieldStartError = false;
        },
        resetLoginForm() {
            this.loginWithIframe = false;
            this.loginUsernameInput = true;
            this.username = '';
            this.$refs.usernameField.reset();
            this.$nextTick(() => this.$refs.usernameField.focus());
        },
        async loginUsername() {
            this.loginUsernameInput = false;
            this.loginWithIframe = true;
            // temporary until we fix loginshield integration, use cryptium id instead of loginshield:
            this.startCryptiumId({ email: this.username });
            // this.startLoginShield({ username: this.username, interactionId: this.interactionId, rememberMe: true });
        },
        onResult(result) {
            switch (result.status) {
            case 'verify':
                this.finishLoginShield({ verifyToken: result.verifyToken });
                break;
            case 'error':
                if (this.$route.query.mode === 'activate-loginshield') {
                    this.$router.push('/create-account');
                    return;
                }
                this.loginshieldStartError = true;
                this.resetLoginForm();
                break;
            case 'cancel':
                if (this.$route.query.mode === 'activate-loginshield') {
                    this.$router.push('/create-account');
                    return;
                }
                this.resetLoginForm();
                break;
            default:
                console.error(`Login.vue: onResult: unknown status ${result.status}`);
            }
        },
        async startLoginShield({
            mode, username, interactionId, rememberMe,
        }) {
            this.resetErrors();
            console.log(`startLoginShield: dispatch login with interactionId ${interactionId}`);
            const { error, forward, interactionId: nextInteractionId } = await this.$store.dispatch('login', {
                interactionId, // e.g. create_account or require_login interaction
                username,
                mode,
            });
            console.log(`startLoginShield: response nextInteractionId ${nextInteractionId}`);
            if (nextInteractionId) {
                this.interactionId = nextInteractionId;
            }
            if (forward) {
                let loginMode = null;
                if (mode === 'activate-loginshield') {
                    loginMode = 'link-device'; // disable notifications for this login request
                }
                // redirect to loginshield for login
                // this works, it's the redirect method: window.location = forward;
                loginshieldInit({
                    elementId: 'loginshield-content',
                    backgroundColor: '#efefef',
                    // width: '100%',
                    // height: '600px',
                    action: 'start',
                    mode: loginMode,
                    forward,
                    rememberMe,
                    onResult: this.onResult.bind(this),
                    /*
                    onLogin: ((verifyInfo) => {
                        this.finishLoginShield(verifyInfo);
                    }),
                    onError: ((err) => {
                        console.log('startLoginShield: login failed, error: %o', err);
                        this.loginshieldStartError = true;
                        this.resetLoginForm();
                    }),
                    */
                });
            } else {
                // TODO: show a more specific error message that the account either doesn't exist or
                // doesn't have loginshield enabled, or isn't ready to activate loginshield
                if (error) {
                    console.error(`startLoginShield error: ${error}`);
                }
                if (this.$route.query.mode === 'activate-loginshield') {
                    this.$router.push('/create-account');
                    return;
                }
                this.loginshieldStartError = true;
                this.resetLoginForm();
            }
        },
        async resumeLoginShield({ forward, rememberMe }) {
            this.resetErrors();
            loginshieldInit({
                elementId: 'loginshield-content',
                backgroundColor: '#efefef',
                // width: '100%',
                // height: '600px',
                action: 'resume',
                forward,
                rememberMe,
                onResult: this.onResult.bind(this),
                /*
                onLogin: ((verifyInfo) => {
                    this.finishLoginShield(verifyInfo);
                }),
                onError: ((err) => {
                    console.log('resumeLoginShield: login failed, error: %o', err);
                    this.loginshieldStartError = true;
                    this.resetLoginForm();
                }),
                */
            });
        },
        async finishLoginShield({ verifyToken }) {
            console.log(`finishLoginShield: verifying login with token: ${verifyToken} interactionId: ${this.interactionId}`);
            const {
                isAuthenticated, next, error,
            } = await this.$store.dispatch('login', {
                // mode: 'verify-loginshield',  mode is not even needed, if we send the token the service knows to use it to verify the login ;
                verifyToken,
                interactionId: this.interactionId, // e.g. points to an careate-account or login interaction
            });
            console.log(`finishLoginShield: isAuthenticated ${isAuthenticated} next ${next}`);
            if (isAuthenticated) {
                if (next) {
                    const nextInteraction = await this.$store.dispatch('loadInteraction', next);
                    console.log('finishLoginShield: next interaction: %o', nextInteraction);
                    if (nextInteraction && nextInteraction.type) {
                        switch (nextInteraction.type) {
                        case 'require_login':
                            this.$router.push(nextInteraction.state.redirect);
                            return;
                        default:
                            this.$router.push({ path: '/dashboard', query: { i: next } });
                            return;
                        }
                    }
                }
                this.$router.push('/dashboard');
            } else if (error) {
                console.error(`finishLoginShield error: ${error}`);
                this.loginshieldStartError = true;
                this.resetLoginForm();
                // TODO: if the error is 'unauthorized' show links to login or create account (the
                // two interactions that are available, and we don't indicate to the user which was
                // involved in the requested interaction, since they don't have access) by redirect
                // user to front page
                if (error === 'unauthorized') {
                    this.$router.push('/interaction?error=unauthorized');
                }
            } else {
                // TODO: show a loginshield specific error and then try loginshield login again,
                // because this situation could happen when a phishing attack is circumvented,
                // and the second login would succeed; in this case the call returns
                // isAuthenticated: false, but no error, because the processing is routine
                console.error('finishLoginShield not authenticated');
                this.loginshieldStartError = true;
                this.resetLoginForm();
            }
        },
    },

    mounted() {
        console.log('Login.vue: mounted with isReady: %o and isAuthenticated: %o', this.isReady, this.isAuthenticated);
        if (this.isReady) {
            this.init();
        }
    },
    created() {
        this.interactionId = this.$route.query.i;
        this.username = this.$route.query.email;
    },
};
</script>
